import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useFeedStore } from '@/lib/store';

interface Feed {
  id: string;
  name: string;
  enabled: boolean;
}

const defaultFeeds = [
  { id: 'xfeed', name: 'X Feed', enabled: true },
  { id: 'search', name: 'Targeted Search', enabled: true },
  { id: 'news', name: 'News', enabled: true },
  { id: 'market', name: 'Market Analysis', enabled: true },
  { id: 'nftart', name: 'NFT/Art', enabled: true },
  { id: 'tiktok', name: 'TikTok', enabled: true },
  { id: 'youtube', name: 'YouTube', enabled: true },
  { id: 'instagram', name: 'Instagram', enabled: true },
];

interface EditFeedsModalProps {
  open: boolean;
  onClose: () => void;
}

export function EditFeedsModal({ open, onClose }: EditFeedsModalProps) {
  const { setEnabledFeeds, fetchFeeds } = useFeedStore();
  const [feeds, setFeeds] = useState<Feed[]>(() => {
    const savedFeeds = localStorage.getItem('enabledFeeds');
    return savedFeeds ? JSON.parse(savedFeeds) : defaultFeeds;
  });
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const savedFeeds = localStorage.getItem('enabledFeeds');
    setFeeds(savedFeeds ? JSON.parse(savedFeeds) : defaultFeeds);
    setHasChanges(false);
  }, [open]);

  const handleToggleFeed = (id: string) => {
    setFeeds(feeds.map(feed => 
      feed.id === id ? { ...feed, enabled: !feed.enabled } : feed
    ));
    setHasChanges(true);
  };

  const handleSave = () => {
    const enabledFeedIds = feeds.filter(f => f.enabled).map(f => f.id);
    localStorage.setItem('enabledFeeds', JSON.stringify(feeds));
    setEnabledFeeds(enabledFeedIds);
    fetchFeeds();
    setHasChanges(false);
    onClose();
  };

  const handleCancel = () => {
    const savedFeeds = localStorage.getItem('enabledFeeds');
    setFeeds(savedFeeds ? JSON.parse(savedFeeds) : defaultFeeds);
    setHasChanges(false);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={handleCancel}>
      <DialogContent className="flex max-h-[85vh] flex-col gap-0 p-0 sm:max-w-[425px]">
        <DialogHeader className="border-b border-border p-6 pb-8">
          <DialogTitle>Edit RSS Feeds</DialogTitle>
        </DialogHeader>

        <ScrollArea className="flex-1 px-6">
          <div className="space-y-4 py-6">
            {feeds.map((feed) => (
              <div
                key={feed.id}
                className="flex items-center justify-between rounded-lg border border-border p-4"
              >
                <div className="flex-1">
                  <span className="font-medium">{feed.name}</span>
                </div>
                <Switch
                  checked={feed.enabled}
                  onCheckedChange={() => handleToggleFeed(feed.id)}
                />
              </div>
            ))}
          </div>
        </ScrollArea>

        {hasChanges && (
          <div className="flex border-t border-border">
            <Button
              variant="ghost"
              className="flex-1 rounded-none py-6"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="flex-1 rounded-none bg-gradient-to-r from-yellow-500 to-green-500 text-white hover:from-yellow-600 hover:to-green-600"
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}