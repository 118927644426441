import { useState } from 'react';
import { Bell, Menu, Star, MessageSquare, Trophy, Zap, LogIn, Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo } from './Logo';
import { NotificationBadge } from './NotificationBadge';
import { SearchModal } from './SearchModal';
import { SummaryModal } from './summary/SummaryModal';
import { useFeedStore } from '@/lib/store';
import { useAuth } from '@/lib/auth';

interface HeaderProps {
  className?: string;
  onMenuClick: () => void;
}

export default function Header({ className, onMenuClick }: HeaderProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSearch, setShowSearch] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const { feeds } = useFeedStore();
  const { isAuthenticated } = useAuth();

  const handleNotificationClick = () => {
    if (location.pathname === '/notifications') {
      navigate('/');
    } else {
      navigate('/notifications');
    }
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  return (
    <header className={`border-b border-border bg-background/80 px-4 backdrop-blur-sm ${className}`}>
      <div className="flex h-16 items-center justify-between">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            className="lg:hidden"
            onClick={onMenuClick}
          >
            <Menu className="h-5 w-5" />
          </Button>
          <Logo className="lg:hidden" />
        </div>
        
        <div className="flex flex-1 items-center justify-end gap-2">
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => setShowSearch(true)}
          >
            <Search className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => setShowSummary(true)}
          >
            <Zap className="h-4 w-4" />
          </Button>
          {isAuthenticated ? (
            <>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => navigate('/favorites')}
              >
                <Star className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => navigate('/leaderboard')}
              >
                <Trophy className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => navigate('/blurts')}
              >
                <MessageSquare className="h-4 w-4" />
              </Button>
              <div className="relative">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  onClick={handleNotificationClick}
                >
                  <Bell className="h-4 w-4" />
                  <NotificationBadge />
                </Button>
              </div>
            </>
          ) : (
            <Button
              variant="gradient"
              size="sm"
              className="bg-gradient-to-r from-yellow-500 to-green-500 text-white hover:from-yellow-600 hover:to-green-600"
              onClick={handleSignIn}
            >
              <LogIn className="mr-2 h-4 w-4" />
              Sign In
            </Button>
          )}
        </div>
      </div>

      <SearchModal 
        open={showSearch}
        onClose={() => setShowSearch(false)}
      />

      <SummaryModal 
        open={showSummary}
        onClose={() => setShowSummary(false)}
        stories={[]}
      />
    </header>
  );
}