import { create } from 'zustand';

interface AuthState {
  user: {
    id: string;
    username: string;
    avatar?: string;
  } | null;
  isAuthenticated: boolean;
  signInWithX: () => Promise<void>;
  signOut: () => void;
}

export const useAuth = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  signInWithX: async () => {
    // Mock X authentication
    // In production, this would integrate with X's OAuth flow
    set({
      user: {
        id: '123',
        username: 'CryptoTrader',
        avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=Felix'
      },
      isAuthenticated: true
    });
  },
  signOut: () => {
    set({ user: null, isAuthenticated: false });
  }
}));