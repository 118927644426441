import { LineChart } from 'lucide-react';
import { TokenBanner } from '@/components/TokenBanner';
import { MarketSentiment } from '@/components/predictions/MarketSentiment';
import { GroupSentiment } from '@/components/predictions/GroupSentiment';

export default function Predictions() {
  return (
    <div className="space-y-8 pt-8">
      <div className="flex items-center gap-4">
        <LineChart className="h-10 w-10 text-primary" />
        <div>
          <h1 className="text-4xl font-bold">Market Sentiment</h1>
          <p className="text-muted-foreground">Market analysis and predictions</p>
        </div>
      </div>

      <div className="sticky top-16 z-30 -mx-4 bg-background px-4 pb-4">
        <TokenBanner />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <MarketSentiment />
        <GroupSentiment />
      </div>
    </div>
  );
}