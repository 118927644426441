import { useState, useMemo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Search, ExternalLink } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { tokens } from '@/lib/tokens';
import { useFeedStore } from '@/lib/store';
import { format } from 'date-fns';

interface SearchModalProps {
  open: boolean;
  onClose: () => void;
}

export function SearchModal({ open, onClose }: SearchModalProps) {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const { feeds } = useFeedStore();

  const handleTokenClick = (token: any) => {
    navigate(`/token/${token.symbol.toLowerCase()}`);
    onClose();
  };

  const filteredTokens = tokens
    .filter(token =>
      token.name.toLowerCase().includes(query.toLowerCase()) ||
      token.symbol.toLowerCase().includes(query.toLowerCase())
    )
    .slice(0, 3);

  const filteredArticles = useMemo(() => {
    if (!query) return [];
    
    const allArticles = Object.values(feeds).flat();
    const searchQuery = query.toLowerCase();
    
    return allArticles
      .filter(article => 
        article.title?.toLowerCase().includes(searchQuery) ||
        article.description?.toLowerCase().includes(searchQuery)
      )
      .reduce((unique: any[], article) => {
        const isDuplicate = unique.some(a => a.link === article.link);
        if (!isDuplicate) {
          unique.push(article);
        }
        return unique;
      }, [])
      .slice(0, 5);
  }, [feeds, query]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="sr-only">Search</DialogTitle>
        </DialogHeader>
        
        <div className="relative">
          <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
          <Input
            placeholder="Search tokens, news..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="pl-9"
            autoFocus
          />
        </div>

        {filteredTokens.length > 0 && (
          <>
            <div className="mt-4 text-sm font-medium text-muted-foreground">Tokens</div>
            <div className="space-y-2">
              {filteredTokens.map((token) => (
                <button
                  key={token.symbol}
                  className="flex w-full items-center gap-3 rounded-lg p-2 text-left hover:bg-muted"
                  onClick={() => handleTokenClick(token)}
                >
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary/10">
                    <span className="text-xs font-medium">{token.symbol}</span>
                  </div>
                  <div>
                    <div className="font-medium">{token.name}</div>
                    <div className="text-sm text-muted-foreground">{token.symbol}</div>
                  </div>
                </button>
              ))}
            </div>
          </>
        )}

        {filteredArticles.length > 0 && (
          <>
            <div className="mt-4 text-sm font-medium text-muted-foreground">Articles</div>
            <div className="space-y-2">
              {filteredArticles.map((article) => (
                <a
                  key={article.link}
                  href={article.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-start gap-3 rounded-lg p-2 text-left hover:bg-muted"
                >
                  {article.image && !article.image.includes('undefined') && (
                    <div className="relative h-16 w-24 flex-shrink-0 overflow-hidden rounded-md">
                      <img
                        src={article.image}
                        alt=""
                        className="h-full w-full object-cover"
                        onError={(e) => {
                          const target = e.target as HTMLElement;
                          target.style.display = 'none';
                        }}
                      />
                    </div>
                  )}
                  <div className="flex-1 overflow-hidden">
                    <div className="flex items-center gap-2">
                      <span className="flex-1 font-medium line-clamp-1">{article.title}</span>
                      <ExternalLink className="h-3 w-3 flex-shrink-0 text-muted-foreground" />
                    </div>
                    {article.description && (
                      <div className="mt-1 text-sm text-muted-foreground line-clamp-2">
                        {article.description.replace(/<[^>]*>/g, '')}
                      </div>
                    )}
                    <div className="mt-1 flex items-center gap-2 text-xs text-muted-foreground">
                      <span>{new URL(article.link).hostname.replace('www.', '')}</span>
                      {article.pubDate && (
                        <>
                          <span>•</span>
                          <span>{format(new Date(article.pubDate), 'MMM d, yyyy')}</span>
                        </>
                      )}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </>
        )}

        {query && filteredTokens.length === 0 && filteredArticles.length === 0 && (
          <div className="mt-4 text-center text-sm text-muted-foreground">
            No results found for "{query}"
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}