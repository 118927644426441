import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Zap } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { cn } from '@/lib/utils';

interface Story {
  id: string;
  title: string;
  link: string;
  pubDate: string;
  source?: string;
}

interface SummaryModalProps {
  open: boolean;
  onClose: () => void;
  stories: Story[];
}

export function SummaryModal({ open, onClose, stories }: SummaryModalProps) {
  const topStories = stories.slice(0, 3);
  const otherStories = stories.slice(3);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[85vh] sm:max-w-[600px]">
        <DialogHeader className="pb-6">
          <DialogTitle className="flex items-center gap-3 text-2xl">
            <Zap className="h-7 w-7 text-primary" />
            Top Stories Summary
          </DialogTitle>
        </DialogHeader>

        <ScrollArea className="h-[calc(85vh-120px)] pr-4">
          <div className="space-y-6">
            <div className="rounded-lg bg-gradient-to-r from-yellow-500/10 to-green-500/10 p-4">
              <h3 className="mb-4 font-medium text-primary">Trending Now</h3>
              <div className="space-y-4">
                {topStories.map((story, index) => (
                  <a
                    key={story.id}
                    href={story.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-start gap-4 rounded-lg border border-border bg-card/50 p-4 transition-colors hover:bg-muted/50"
                  >
                    <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-primary/20 text-sm font-bold text-primary">
                      {index + 1}
                    </div>
                    <div className="flex-1 space-y-1">
                      <h3 className="font-medium leading-tight">{story.title}</h3>
                      <div className="flex items-center gap-2 text-xs text-muted-foreground">
                        {story.source && (
                          <>
                            <span>{story.source}</span>
                            <span className="text-muted-foreground/50">•</span>
                          </>
                        )}
                        <span>{formatDistanceToNow(new Date(story.pubDate), { addSuffix: true })}</span>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="font-medium text-muted-foreground">More Stories</h3>
              {otherStories.map((story, index) => (
                <a
                  key={story.id}
                  href={story.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-start gap-4 rounded-lg border border-border p-4 transition-colors hover:bg-muted/50"
                >
                  <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-muted text-sm font-medium text-muted-foreground">
                    {index + 4}
                  </div>
                  <div className="flex-1 space-y-1">
                    <h3 className="font-medium leading-tight">{story.title}</h3>
                    <div className="flex items-center gap-2 text-xs text-muted-foreground">
                      {story.source && (
                        <>
                          <span>{story.source}</span>
                          <span className="text-muted-foreground/50">•</span>
                        </>
                      )}
                      <span>{formatDistanceToNow(new Date(story.pubDate), { addSuffix: true })}</span>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}